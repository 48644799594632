const prod = process.env.NODE_ENV === 'production';

export default {
  siteName: 'Reno',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,
  wentWrongTxt: 'Something went wrong. Try later',
  enableAnimatedRoute: false,

  // apiUrl: 'http://192.168.0.107:1337/api/',
  apiUrl: 'https://reno-home.azurewebsites.net/',
  siteUrl: 'https://reno-home.azurewebsites.net/',
  csvUrl: 'https://localhost:1337/SampleCsv',
  domainUrl: prod ? 'http://147.182.130.103:1337/' : 'http://localhost:3000',
  sailsUrl: 'http://147.182.130.103:1337/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'fmgz7dzes6qjb1apg4645ac54vumgn4xkksqmsxd9xk819sv',
  dashboard: '/dashboard',
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  postcodeApiKey: process.env.REACT_APP_POSTCODE_API_KEY,
  paypalClientId: '',
};
